import React from "react";
import searchImg from "../../image/menuItems/search.svg";
import "./search.scss";

function Search({setSearchText}) {
  return (
    <div className="search">
      <input type="text" placeholder="Поиск" onChange={(event) => setSearchText(event.target.value)} />
      <button>
        <img src={searchImg} alt="" />
      </button>
    </div>
  );
}

export default Search;
